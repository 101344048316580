import { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
// import { apiInstance } from '../httpClient/httpClient';
import { apiInstance } from '../httpClient/index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const LanguageForm = ({ handleClose, getLanguageList, isEdit, singleData }) => {
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [loader, setLoader] = useState(false);
  const [languageData, setLanguageData] = useState([]);

  const handleAddLanguage = async () => {
    setLoader(true);
    const reqObj = {
      name: name,
      languageId: isEdit ? singleData?._id : '',
    };
    if (isEdit) {
      try {
        const res = await apiInstance.put('languagevs/update', reqObj);
        
        if (res.status === 200) {
          // setSayariData(res.data.data);
          setLoader(false);
          handleClose();
          getLanguageList();
        }
      } catch (error) {
        
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('languagevs/create', reqObj);
        if (res.status === 200) {
          // setSayariData(res.data.data);
          setLoader(false);
          handleClose();
          getLanguageList();
        }
      } catch (error) {
        
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 className="m-0">{isEdit ? 'Edit Language' : 'Add Language'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
        </Stack>

        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddLanguage}
          disableElevation
        >
          {isEdit ? 'Update' : 'Add Language'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default LanguageForm;
