import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { apiInstance } from 'src/httpClient';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [indstriesCount,setIndstriesCount] = useState();
  const [citiesCount,setCitiesCount] = useState();
  const [usersCount,setUsersCount] = useState();
  const [eventsCount,setEventsCount] = useState();
  useEffect(()=>{
    Industries();
    Cities();
    Users();
    Events();
  },[])

  const Industries = async () => {

    try {
      const res = await apiInstance.get('/industry/get');
      if (res.status === 200) {
      setIndstriesCount(res.data.data.length);
      }
    } catch (error) {
    }
  };
  const Cities = async () => {
    try {
      const res = await apiInstance.get('/city/get');
      if (res.status === 200) {
      setCitiesCount(res.data.data.length);
      }
    } catch (error) {
    }
  };
  const Users = async () => {
    try {
      const res = await apiInstance.get('/user/get');
      if (res.status === 200) {
      setUsersCount(res.data.data.length);
      }
    } catch (error) {
    }
  };
  const Events = async () => {
    try {
      const res = await apiInstance.get('/event/get');
      if (res.status === 200) {
      setEventsCount(res.data.data.length);
      }
    } catch (error) {
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Industries"
              total={indstriesCount}
              icon={'bx:category'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Cities"
              total={citiesCount}
              color="info"
              icon={'mdi:contract-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Users"
              total={usersCount}
              color="warning"
              icon={'material-symbols:language'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Events"
              total={eventsCount}
              color="error"
              icon={'majesticons:chat-status-line'}
            />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
