import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { apiInstance } from '../httpClient/index';
import UserMoreMenu from '../sections/@dashboard/user/UserMoreMenu';
import { read, utils } from 'xlsx';
import AppCommonSummary from 'src/sections/@dashboard/app/AppCommonSqure';
import QuotesEnglishForm from 'src/components/QuotesEnglishForm';
import MyAffirmationQuotes from 'src/components/MyAffirmationQuotes';
import MyAffirmationQuotesImageForm from 'src/components/MyAffirmationQuotesImageForm';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'src/utils/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'category', label: 'Category Image', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.quotes.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AffirmationQuotesImage() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openModal, setOpenModal] = useState(false);

  const [status, setStatus] = useState('typing');

  const [loading, setLoading] = useState(false);
  const [quotesData, setQuotesData] = useState([]);
  const [Excel, setExcel] = useState({});
  const navigate = useNavigate()
  const token = getToken()
  
  useEffect(() => {
    showQuotesImage();
  });
  
  const showQuotesImage = async () => {
    if(!token){
      navigate('/')
    }
    setLoading(true);
    try {
      const res = await apiInstance.get('quotimagemyaff/');

      if (res.status === 200) {
        setQuotesData(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOpen = (data) => {
    // if (data != '') {
    //   setData(data);
    // } else {
    //   setData('');
    // }
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotesData.length) : 0;

  const filteredUsers = applySortFilter(quotesData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  let file;
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      file = files[0];

      const reader = new FileReader();

      reader.onload = (event) => {
        const wb = read(event.target.result);

        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          setExcel(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const blukReport = async () => {
    const reqObj = Excel;
    setLoading(true);
    try {
      const response = await apiInstance.post('status/create', reqObj);

      setLoading(false);
      showQuotesImage();
    } catch (e) {}
  };

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} mb={2}>
            <AppCommonSummary title="MyAffirmation Quotes" total={quotesData ? quotesData?.length : '0'} />
          </Grid>
        </Grid>
        <Helmet>
          <title> MyAffirmation QuotesImage </title>
        </Helmet>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" style={{ display: 'felx', alignItems: 'center' }} gutterBottom>
            MyAffirmation QuotesImage
          </Typography>
          {/* <label htmlFor="contained-button-file" style={{ display: 'flex', alignItems: 'center' }} >
                        <Input id="contained-button-file" multiple type="file" />
                        <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
                            Bluk upload
                            <input name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple type="file" />
                            <label className="custom-file-label" htmlFor="inputGroupFile" />
                        </Button>
                        <Button variant='contained' onClick={blukReport} component="label" sx={{ borderRadius: '0px' }}>
                            Upload
                        </Button>
                    </label> */}
          <Button
            variant="contained"
            onClick={handleOpen}
            style={{ height: '40px' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add MyAffirmation QuotesImage
          </Button>
        </Stack>

        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <MyAffirmationQuotesImageForm handleClose={handleClose} showQuotesImage={showQuotesImage} />
        </Modal>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            type="MyAffirmation QuotesImage"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={quotesData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, category,image } = row;
                    const selectedUser = selected.indexOf(category) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt="Category Image" src={image[0]} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{category?.name}</TableCell>
                        {/* <TableCell align="left"><img src={image[0]} width={100} alt='quotes image'/></TableCell> */}
                        <TableCell align="right">
                          <UserMoreMenu type="MyAffirmationQuotesImage" data={row} showQuotesImage={showQuotesImage} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={quotesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
