import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const WallpaperEnglishForm = ({ handleClose, singleData, getAllWallpaperEnglish, isEdit }) => {
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [type, setType] = useState(singleData ? singleData.type : '');
  const [file, setFile] = useState('');
  const [loader, setLoader] = useState(false);
  const [fileData, setFileDate] = useState('');
  const [category, setCategory] = useState(singleData ? singleData.category?._id : '');
  const [categoryData, setCategoryData] = useState([]);
  const [errors, setError] = useState({
    name: '',
    type: '',
    category: '',
  });
  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('categoryvs/get?type=Wallpaper-English');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) {}
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleUploadImage = async (e) => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('title', name);
    formData.append('type', 'image');
    formData.append('files', e.target.files[0]);

    try {
      const res = await apiInstance.post('filesvs/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        setFileDate(res.data.data);
      }
    } catch (error) {}
  };

  const handleAddWallaper = async () => {
    if (!name.length && !type.length && !category.length && !fileData.length) {
      setError({
        ...errors,
        name: 'name is requried',
        type: 'type is requried',
        category: 'category is requried',
        fileData: 'image is requried',
      });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'name is requried' });
      return;
    }
    if (!type) {
      setError({ ...errors, type: 'type is requried' });
    }
    if (!category) {
      setError({ ...errors, category: 'category is requried' });
    }
    if (!fileData) {
      setError({ ...errors, fileData: 'image is requried' });
    }
    if (name && type && category && fileData) {
      setError({ name: '', type: '', category: '', fileData: '' });
    }
    const redObj = {
      name: name,
      image: {
        url: fileData?.url,
        // public_id: fileData?.public_id,
      },
      type: type,
      category: category,
      wallpaperId: isEdit ? singleData._id : '',
    };
    setLoader(true);
    if (isEdit) {
      try {
        const res = await apiInstance.put('wallpapervs/update', redObj);

        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllWallpaperEnglish();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('wallpapervs/create', redObj);

        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllWallpaperEnglish();
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>Add wallpaper-English</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack sx={{ marginTop: 2 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
        </Stack>
        <Stack spacing={2} sx={{ marginTop: '3%' }}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Type"
                onChange={handleCategory}
              >
                {categoryData &&
                  categoryData.map((ele) => {
                    return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                  })}
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.category : ''}</span>
            </FormControl>
          </Grid>
        </Stack>
        <Stack spacing={2} sx={{ marginTop: '3%' }}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="English">English</MenuItem>
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.type : ''}</span>
            </FormControl>
          </Grid>
        </Stack>
        <Stack sx={{ marginTop: 2 }}>
          <Grid container item xs={12}>
            <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
              Image upload
              <input hidden accept="image/*" multiple type="file" onChange={handleUploadImage} />
            </Button>
          </Grid>
          <span style={{ color: '#f00' }}>{errors ? errors.fileData : ''}</span>

          <br />
          <div className="d-block">
            {/* <h4>{file ? file.name : ''}</h4> */}
            {fileData ? (
              <img src={fileData?.url} alt="img" style={{ height: '100px', width: '100px' }} />
            ) : singleData && singleData.image ? (
              <img src={singleData?.image?.url} alt="img" style={{ height: '100px', width: '100px' }} />
            ) : null}
          </div>
        </Stack>

        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddWallaper}
          disableElevation
        >
          {isEdit ? 'Edit Wallpaper-English' : 'Add Wallpaper-English'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default WallpaperEnglishForm;
