/* eslint no-useless-return: "error" */
import { useState, useEffect } from 'react';
import {
  CircularProgress,
  TextField,
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient';
// import { apiInstance } from '../httpClient/httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const VideoStatusForm = ({ handleClose, singleData, isEdit, getAllVideoStatus }) => {
  const [name, setName] = useState(singleData ? singleData.title : '');
  const [file, setFile] = useState('');
  const [category, setCategory] = useState(singleData ? singleData.category?._id : '');
  const [language, setLanguage] = useState(singleData ? singleData.language?._id : '');
  const [loader, setLoader] = useState(false);
  const [fileData, setFileDate] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [errors, setError] = useState({
    lan: '',
    category: '',
    name: '',
    fileData: '',
  });
  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {
    getAllCategory();
    getAllLanguage();
  }, []);

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('categoryvs/get?type=Video');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) {}
  };
  const getAllLanguage = async () => {
    try {
      const res = await apiInstance.get('languagevs/get');
      if (res.status === 200) {
        setLanguageData(res.data.data);
      }
    } catch (error) {}
  };

  const handleUploadFile = async (e) => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('title', name);
    formData.append('type', 'video');
    formData.append('files', e.target.files[0]);
    setLoader(true);
    try {
      const res = await apiInstance.post('filesvs/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 200) {
        setFileDate(res.data.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleAddVideoStatus = async () => {
    if (!language.length && !category.length && !name.length && !fileData.length) {
      setError({
        ...errors,
        lan: 'language is required',
        category: 'category is required',
        name: 'name is requried',
        fileData: 'video is requried',
      });
      return;
    }
    if (!category) {
      setError({ ...errors, category: 'category is required' });
      return;
    }
    if (!language) {
      setError({ ...errors, lan: 'language is required' });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'name is required' });
      return;
    }
    if (!fileData) {
      setError({ ...errors, fileData: 'video is required' });
      return;
    }
    if (language && category && name && fileData) {
      setError({
        lan: '',
        category: '',
        name: '',
        fileData: '',
      });
    }
    const redObj = {
      title: name,
      video: {
        url: fileData?.url,
        // public_id: fileData?.public_id,
      },
      category: category,
      language: language,
      type: 'Normal',
      like: likeRandom,
      view: viewRandom,
      videostatusId: isEdit ? singleData._id : '',
    };

    setLoader(true);
    if (isEdit) {
      try {
        const res = await apiInstance.put('videostatusvs/update', redObj);
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllVideoStatus();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('videostatusvs/create', redObj);

        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllVideoStatus();
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };

  let like = Math.random();
  let likeRandom = like.toString().substring(2, 4);

  let view = Math.random();
  let viewRandom = view.toString().substring(2, 4);

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isEdit ? 'Edit Video Status' : 'Add Video Status'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid container item xs={6}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
          <Grid container item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Type"
                onChange={handleCategory}
              >
                {categoryData &&
                  categoryData.map((ele) => {
                    return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                  })}
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.category : ''}</span>
            </FormControl>
          </Grid>
          <Grid container item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="language">Language</InputLabel>
              <Select labelId="language" id="language" value={language} label="Type" onChange={handleLanguage}>
                {languageData &&
                  languageData.map((ele) => {
                    return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                  })}
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.lan : ''}</span>
            </FormControl>
          </Grid>
        </Grid>

        <Stack sx={{ marginTop: 2 }}>
          <Grid container item xs={12}>
            <form>
              <label htmlFor="contained-button-file" style={{ display: 'flex', alignItems: 'start' }}>
                <Input id="contained-button-file" multiple type="file" />
                <div>
                  <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
                    Video upload
                    <input hidden accept="video/*" multiple type="file" onChange={handleUploadFile} />
                  </Button>
                  <h4>{file ? file.name : ''}</h4>
                </div>
              </label>
                <span style={{ color: '#f00' }}>{errors ? errors.fileData : ''}</span>
              <div className="d-block">
                {fileData ? (
                  <video controls src={fileData?.url} style={{ height: '100px', width: '200px' }} />
                ) : singleData && singleData.video ? (
                  <video src={singleData?.video?.url} controls style={{ height: '100px', width: '200px' }} />
                ) : null}
              </div>
            </form>
            <br />
          </Grid>
        </Stack>
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddVideoStatus}
          disableElevation
        >
          {isEdit ? 'Update' : 'Add Video status'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default VideoStatusForm;
