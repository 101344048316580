import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import Simple from '../assets/Simple.jpg'
// sections
import modulesStyle from '../custom/css/loginPage.module.css'
import RegisterForm from 'src/sections/auth/register/RegisterForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  // backgroundColor: theme.palette.background.default,
  backgroundColor: 'white'
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  // const mdUp = useResponsive('up', 'md');

  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <Helmet>
          <title> Registration  </title>
        </Helmet>

        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />

          {/* {mdUp && ( */}
          <StyledSection sx={{ maxWidth: '800px', margin: 'none',background:'#2E77F2' }}>
            <img src={Simple} alt="simple" sx={{ mt: 20 }} />
          </StyledSection>
          {/* )} */}

          <Container >
            <StyledContent className={modulesStyle.css}>
              <Typography variant="h4" gutterBottom mb={3}>
                Create a new account
              </Typography>
              <RegisterForm />
            </StyledContent>
          </Container>
        </StyledRoot>
      </div>
    </>
  );
}
