import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Forget from './pages/Forget';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoryPage from './pages/CategoryPage';
import SayariPage from './pages/SayariPage';
import Status from './pages/Status';
import WallpaperPage from './pages/WallpaperPage';
import LanguagePage from './pages/LanguagePage';
import VideoStatusPage from './pages/VideoStatusPage';
import FullScreenVideoPage from './pages/FullScreenVideoPage';
import StatusImage from './pages/Status-image';
import SayariImage from './pages/Sayari-image'
import WallpeprHindi from './pages/WallpeprHindi';
import WallpeprEnglish from './pages/WallpeprEnglish';
import JokesPage from './pages/JokesPage';
import JokeImagePage from './pages/JokeImagePage';
import Role from './pages/Role';
import ShayariEnglishPage from './pages/ShayariEnglish';
import StatusHindi from './pages/StatusHindi';
import StatusEnglish from './pages/StatusEnglish';
import JokesHindiPage from './pages/JokesHindi';
import JokesEnglishPage from './pages/JokesEnglish';
import Otp from './pages/Otp';
import UserListing from './pages/UserListing';
import ColorPage from './pages/ColorPage';
import WallpeprDp from './pages/WallpaperDp';
import ResetPassword from './pages/ResetPassword';
import IndustryPage from './pages/IndustryPage';
import AuthorPage from './pages/AuthorPage';
import EventPage from './pages/EventPage';
import Citypage from './pages/CityPage';
import AffirmationQuotes from './pages/AffirmationQuotesPage';
import AffirmationQuotesImage from './pages/AffirmationQuotesImage';
import RegisterPage from './pages/RegisterPage'
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'category', element: <CategoryPage /> },
        { path: 'industry', element: <IndustryPage /> },
        { path: 'userlisting', element: <UserListing /> },
        { path: 'eventpage', element: <EventPage /> },
        { path: 'city', element: <Citypage /> },
        { path: 'color', element: <ColorPage /> },
        { path: 'sayari', element: <SayariPage /> },
        { path: 'role', element: <Role /> },
        { path: 'sayariEnglish', element: <ShayariEnglishPage /> },
        { path: 'sayariImage', element: <SayariImage /> },
        { path: 'status', element: <Status /> },
        { path: 'statusHindi', element: <StatusHindi /> },
        { path: 'statusEnglish', element: <StatusEnglish /> },
        { path: 'language', element: <LanguagePage /> },
        { path: 'wallpaper', element: <WallpaperPage /> },
        { path: 'wallpaperDp', element: <WallpeprDp /> },
        { path: 'wallpaperHindi', element: <WallpeprHindi /> },
        { path: 'wallpaperEnglish', element: <WallpeprEnglish /> },
        { path: 'statusImage', element: <StatusImage /> },
        { path: 'video-status', element: <VideoStatusPage /> },
        { path: 'full-video', element: <FullScreenVideoPage /> },
        { path: 'jokes', element: <JokesPage /> },
        { path: 'jokesHindi', element: <JokesHindiPage /> },
        { path: 'jokesEnglish', element: <JokesEnglishPage /> },
        { path: 'jokesImage', element: <JokeImagePage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'author', element: <AuthorPage /> },
        { path: 'myAffirmationQuotes', element: <AffirmationQuotes /> },
        { path: 'myAffirmationQuotesImage', element: <AffirmationQuotesImage /> },
        
      ],
    },
    {
      path: '/',
      element: <LoginPage />,
      
    },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'register', element: <RegisterPage /> },
        { path: 'forget', element: <Forget /> },
        { path: 'otp', element: <Otp /> },
        { path: 'reset', element: <ResetPassword /> },    
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
