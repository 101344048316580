import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
  FormLabel,
  Chip,
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient/index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const IndustryForm = ({ handleClose, isEdit, singleData, showCategory }) => {
  console.log("singleData",singleData);
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [colour, setColour] = useState(singleData?.colour || '');
  const [file, setFile] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const inputRef = React.useRef(null);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState('typing');

  if (status === 'success') {
    return <h1>That's right!</h1>;
  }
  const data = {
    "name": name,
  };
  const AddQuotes = async () => {
    setLoader(true);
    setStatus('submitting');
    if (isEdit) {
      try {
        const res = await apiInstance.put(`/industry/update/${singleData._id}`, data);
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showCategory();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('/industry/create', data);
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showCategory();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    }
  };
  const handleUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>{isEdit ? 'Edit Industry' : 'Add Industry'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Grid container item xs={12}>
            <TextField
              placeholder="Add here Industry..."
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              type="text"
              style={{ width: '100%', height: '100%' }}
              id="outlined-multiline-flexible"
              label="Industry Name"
              multiline
              maxRows={4}
              disabled={status === 'submitting'}
            />
          </Grid>
        </Stack>
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%', height: '100%' }}
          onClick={AddQuotes}
        >
          {isEdit ? 'Update Industry' : 'Add Industry'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default IndustryForm;
