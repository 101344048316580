import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Page from '../components/page';
import Logo from '../components/logo/Logo';
// sections
import LoginImage from '../assets/Simple.jpg';
import { Helmet } from 'react-helmet-async';
import modulesStyle from '../custom/css/loginPage.module.css'
import OtpForm from 'src/sections/auth/otp/OtpForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: 'white'
  }));
  
  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // padding: theme.spacing(12, 0),
  }));
  

// ----------------------------------------------------------------------

export default function Otp() {
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <Helmet>
                    <title> OTP  </title>
                </Helmet>

                <StyledRoot>
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, sm: 24, md: 40 },
                            left: { xs: 16, sm: 24, md: 40 },
                        }}
                    />

                    <StyledSection sx={{ maxWidth: '800px', margin: 'none' }}>
                        <img src={LoginImage} alt="simple" sx={{ mt: 20 }} />
                    </StyledSection>

                    <Container >
                        <StyledContent className={modulesStyle.css}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                OTP
                            </Typography>
                            <OtpForm />
                        </StyledContent>
                    </Container>
                </StyledRoot>
            </div>
        </>
    );
}