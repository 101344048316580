import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal,
    Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { apiInstance } from '../httpClient/index';
import UserMoreMenu from '../sections/@dashboard/user/UserMoreMenu';
import { read, utils, } from 'xlsx';
import QuotesEnglishForm from '../components/QuotesEnglishForm'
import AppCommonSummary from 'src/sections/@dashboard/app/AppCommonSqure';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'src/utils/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'Profile', label: 'Profile', alignRight: false },
    { id: 'Fname', label: 'Fname', alignRight: false },
    { id: 'Lname', label: 'Lname', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'Phone', label: 'Phone', alignRight: false },
    { id: 'Code', label: 'Code', alignRight: false },
    { id: 'Industry', label: 'Industry', alignRight: false },
    { id: 'Block', label: 'Block', alignRight: false },
    { id: 'activity', label: 'Activity', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 || _user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1 || _user.industryName.toLowerCase().indexOf(query.toLowerCase()) !== -1 );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserListing() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openModal, setOpenModal] = useState(false);

    const [status, setStatus] = useState('typing');

    const [loading, setLoading] = useState(false);
    const [quotesData, setQuotesData] = useState([]);
    const [Excel, setExcel] = useState({});
    const navigate = useNavigate()
    const token = getToken()

    useEffect(() => {
        showQuotes();
    },[]);

    const showQuotes = async () => {
        setLoading(true);
        try {
            const res = await apiInstance.get('/user/get');
            if (res.status === 200) {
                setQuotesData(res.data.data);
                setLoading(false);
            }
        } catch (error) {

            setLoading(false);
        }
    };

    const handleOpen = (data) => {
        // if (data != '') {
        //   setData(data);
        // } else {
        //   setData('');
        // }
        setOpenModal(true);        
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //         const newSelecteds = USERLIST.map((n) => n.name);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotesData.length) : 0;

    const filteredUsers = applySortFilter(quotesData, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;


    return (
        <>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3} mb={2}>
                        <AppCommonSummary title="User" total={quotesData ? quotesData?.length : '0'} />
                    </Grid>
                </Grid>
                <Helmet>
                    <title> User</title>
                </Helmet>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" style={{display:'felx',alignItems:'center'}} gutterBottom>
                        User Listing
                    </Typography>
                    {/* <label htmlFor="contained-button-file" style={{ display: 'flex', alignItems: 'center' }} >
                        <Input id="contained-button-file" multiple type="file" />
                        <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
                            Bluk upload
                            <input name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple type="file" />
                            <label className="custom-file-label" htmlFor="inputGroupFile" />
                        </Button>
                        <Button variant='contained' onClick={blukReport} component="label" sx={{ borderRadius: '0px' }}>
                            Upload
                        </Button>
                    </label> */}
                    {/* <Button variant="contained" onClick={handleOpen} style={{height:'40px'}} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Block User
                    </Button> */}
                </Stack>

                {/* <Modal
                    open={openModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    disableBackdropClick
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose();
                        }
                    }}
                > 
                    <QuotesEnglishForm handleClose={handleClose} showQuotes={showQuotes} />
                     </Modal> */}

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        type="User"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={quotesData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                   // onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        console.log("row",row);
                                        const {_id, countrycode,email,firstName,lastName,image,industry,phone,activity,isblock } = row;
                                        const selectedUser = selected.indexOf(firstName) !== -1;
                                        console.log(isblock);
                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell component="th" scope="row">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar alt="Category Image" src={image} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{firstName}</TableCell>
                                                <TableCell align="left">{lastName}</TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{phone}</TableCell>
                                                <TableCell align="left">{countrycode}</TableCell>
                                                <TableCell align="left">{industry.map((e)=>{return e.name.replace("",",")})}</TableCell>
                                                <TableCell align="left">{isblock?"Block":"UnBlock"}</TableCell>
                                                <TableCell align="left">{activity}</TableCell>
                                                <TableCell align="right">
                                                    <UserMoreMenu type="quotesEnglish" data={row} showQuotes={showQuotes} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={quotesData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </>
    );
}
