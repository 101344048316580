import { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
// import { apiInstance } from '../httpClient/httpClient';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const SayariFormHindi = ({ handleClose, getSayariDataHindi, isEdit, singleData }) => {
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [color, setColor] = useState(singleData ? singleData.color : '');
  const [loader, setLoader] = useState(false);
  const [errors, setError] = useState({
    name: '',
    type: '',
    category: '',
  });

  const handleAddSayari = async () => {
    setLoader(true);
    const reqObj = {
      name: name,
      color:color
    };
    if (isEdit) {
      try {
        const res = await apiInstance.put(`/tag/update/${singleData._id}`, reqObj);
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getSayariDataHindi();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('/tag/create', reqObj);
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getSayariDataHindi();
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 className="m-0">{isEdit ? 'Edit Role' : 'Add Role'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
        </Stack>
        <Stack spacing={2} sx={{ marginTop: '3%' }}>
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Color</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Please Status..."
                  value={color}
                  label="Color"
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                >
                  <MenuItem value={"1"}>Red</MenuItem>
                  <MenuItem value={"2"}>Green</MenuItem>
                  <MenuItem value={"3"}>Blue</MenuItem>
                  <MenuItem value={"4"}>Orange</MenuItem>
                  <MenuItem value={"5"}>Yellow</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Stack>

        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddSayari}
          disableElevation
        >
          {isEdit ? 'Update Role' : 'Add Role'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default SayariFormHindi;
