import { useState } from 'react';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { apiInstance } from '../../../httpClient';
import { getEmail, getForgotToken, getToken, removeTokenSession } from '../../../utils/common'
// ----------------------------------------------------------------------

const ResetForm = () => {
    const [passoword, setPassword] = useState('')
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [oldPass,setOldPass]= useState({
        amount: '',
        password:'',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [email,setEmail] = useState('');
   

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setPassword(event.target.value)
    };

    const handleOldPass = (prop) => (event) => {
        setOldPass({ ...oldPass, [prop]: event.target.value });
        setOldPass(event.target.value)
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowOldPassword = () => {
        setOldPass({
            ...oldPass,
            showPassword: !oldPass.showPassword,
        });
    };

    const handleMouseDownOldPassword = (event) => {
        event.preventDefault();
    };
    // -----------------------------------------------------------------------------------------
    const [conformPass, setConformPass] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    

    const handleOpen = (prop) => (event) => {
        setConformPass({ ...conformPass, [prop]: event.target.value });
        setPassword(event.target.value)
    };

    const OpenClickShowPassword = () => {
        setConformPass({
            ...conformPass,
            showPassword: !conformPass.showPassword,
        });
    };



    const OpenMouse = (event) => {
        event.preventDefault();
    }
    const ButtonClick = async () => {
        if (values.password !== conformPass.password) {
            alert("Passwords don't match");
        } else {
            const data = {
                "email": email,
                "oldPassword" :oldPass,
                "newPassword": conformPass.password,
                
            };
            console.log(data);
            setLoader(true) 
            try {
                const res = await apiInstance.post('/auth/admin/reset-password', data, {
                    headers: {
                        'X-auth-token': getForgotToken()
                    }
                });
                if (res.status === 200) {
                    removeTokenSession()
                    setLoader(false);
                    navigate('/');
                }
            } catch (error) {
                setLoader(false);
                
            }
        }
    }


    return (
        <>
        <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Enter Your Email</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type='email'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    label="Email"
                />
            </FormControl>
        <FormControl fullWidth sx={{mt:3}} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Enter Your Currunt Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={oldPass.showPassword ? 'text' : 'password'}
                    value={oldPass.password}
                    onChange={handleOldPass('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                edge="end"
                            >
                                {oldPass.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 3 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Enter Your New Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 3 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Confirm Your Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={conformPass.showPassword ? 'text' : 'password'}
                    value={conformPass.password}
                    onChange={handleOpen('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={OpenClickShowPassword}
                                onMouseDown={OpenMouse}
                                edge="end"
                            >
                                {conformPass.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <Button variant="contained" sx={{ padding: 1, marginTop: 3 }} onClick={ButtonClick} >
                Save
                {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
            </Button>
        </>
    )
}
export default ResetForm