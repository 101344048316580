import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
  FormLabel,
  Chip,
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const AuthorFormPage = ({ handleClose, isEdit, singleData, showAuthor }) => {
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [file, setFile] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const inputRef = React.useRef(null);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState('typing');
  

  if (status === 'success') {
    return <h1>That's right!</h1>;
  }

  const AddAuthor = async () => {
    setLoader(true);
    setStatus('submitting');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', uploadFile);
    if (isEdit) {
      try {
        const res = await apiInstance.put(`authormotiv/${singleData?._id}`, formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showAuthor();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('authormotiv', formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showAuthor();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    }
  };
  const handleUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>{isEdit ? 'Edit Author' : 'Add Author'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Grid container item xs={12}>
            <TextField
              placeholder="Add here author..."
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              type="text"
              style={{ width: '100%', height: '100%' }}
              id="outlined-multiline-flexible"
              label="Author"
              multiline
              maxRows={4}
              disabled={status === 'submitting'}
            />
          </Grid>
        </Stack>
        <Stack sx={{ marginTop: '10px' }}>
          <Grid container item xs={12}>
            <FormLabel>File</FormLabel>
            <FormControl variant="outlined" fullWidth>
              {!file ? (
                <>
                  <input
                    name="file"
                    hidden
                    type="file"
                    accept=".jpeg,.jpg,.mp4,.avi,.png,.webp"
                    multiple={false}
                    ref={inputRef}
                    onChange={handleUpload}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => {
                      inputRef.current.click();
                    }}
                  >
                    Choose image
                  </Button>
                </>
              ) : (
                <>
                  <Chip label={file?.name || 'File'} />
                </>
              )}
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  margin: 'auto',
                }}
              >
                {file ? (
                  <img src={file} alt="img" style={{ height: '100px', width: '100px' }} />
                ) : singleData && singleData?.image ? (
                  <img src={singleData?.image[0]} alt="img" style={{ height: '100px', width: '100px' }} />
                ) : (
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: '#c5c5c5',
                      textAlign: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '15px',
                    }}
                  >
                    <p>
                      Image <br /> Preview
                    </p>
                  </div>
                )}
              </div>
            </FormControl>
          </Grid>
        </Stack>
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '5%', height: '100%' }}
          onClick={AddAuthor}
          disabled={name.length === 0 || status === 'submitting'}
        >
          {isEdit ? 'Update author' : 'Add author'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default AuthorFormPage;
