import PropTypes from 'prop-types';

import { useState } from 'react';
// @mui
import {
  Grid,
  Box,
  Card,
  Link,
  Typography,
  Stack,
  CardContent,
  CardMedia,
  CardActionArea,
  IconButton,
  Modal,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CategoryForm from '../../../components/CategoryForm';
import { apiInstance } from '../../../httpClient/index';
import WallpaperForm from '../../../components/WallpaperForm';
import VideoStatusForm from '../../../components/VideoStatusForm';
import FullScreenVideoForm from '../../../components/FullScreenVideoForm';
import StatusImageForm from 'src/components/StatusImageForm';
import SayariImageForm from 'src/components/SayariImageForm';
import WallpaperHindiForm from 'src/components/WallpeprHindiForm';
import WallpaperEnglishForm from 'src/components/WallpeprEnglishForm';
import JokeImageForm from 'src/components/JokeImageForm';
import WallpaperDpForm from 'src/components/WallpaperDpForm';
import Iconify from 'src/components/iconify/Iconify';
// import { VisibilityIcon } from '@mui/icons-material/';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({
  data,
  getType,
  getAllCategory,
  isCardSelect,
  getAllJokesImage,
  getAllWallpaperDp,
  getAllWallpaper,
  getAllWallpaperHindi,
  getAllWallpaperEnglish,
  getAllVideoStatus,
  type,
  getAllSayariImage,
  getAllStatusImage,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [singleData, setSingleData] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = (data) => {
    setSingleData(data);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    if (type === 'category') {
      try {
        const res = await apiInstance.delete(`categoryvs/delete?categoryId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllCategory();
        }
      } catch (error) {}
    } else if (type === 'wallpaper') {
      try {
        const res = await apiInstance.delete(`image/delete/${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllWallpaper();
        }
      } catch (error) {}
    } else if (type === 'video-status') {
      try {
        const res = await apiInstance.delete(`videostatusvs/delete?videostatusId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllVideoStatus();
        }
      } catch (error) {}
    } else if (type === 'full-video') {
      try {
        const res = await apiInstance.delete(`videostatusvs/delete?videostatusId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllVideoStatus();
        }
      } catch (error) {}
    } else if (type === 'statusImage') {
      try {
        const res = await apiInstance.delete(`statusvs/delete?statusId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllStatusImage();
        }
      } catch (error) {}
    } else if (type === 'sayariImage') {
      try {
        const res = await apiInstance.delete(`shayarivs/delete?shayariId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllSayariImage();
        }
      } catch (error) {}
    } else if (type === 'wallpaperHindi') {
      try {
        const res = await apiInstance.delete(`wallpapervs/delete?wallpaperId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllWallpaperHindi();
        }
      } catch (error) {}
    } else if (type === 'wallpaperDp') {
      try {
        const res = await apiInstance.delete(`wallpapervs/delete?wallpaperId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllWallpaperDp();
        }
      } catch (error) {}
    } else if (type === 'wallpaperEnglish') {
      try {
        const res = await apiInstance.delete(`wallpapervs/delete?wallpaperId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllWallpaperEnglish();
        }
      } catch (error) {}
    } else if (type === 'jokeImage') {
      try {
        const res = await apiInstance.delete(`jokevs/delete/?jokeId=${id}`);
        if (res.status === 200) {
          setLoading(false);
          getAllJokesImage();
        }
      } catch (error) {}
    }
  };
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {loading && <CircularProgress />}
        <>
          {!isCardSelect && data && data.length > 0
            ? data.map((ele) => {
                return (
                  <Grid item xs={3}>
                    <Card sx={{ maxWidth: 345, marginTop: 5 }} key={type === 'video-status' ? ele?.video?.url : null}>
                      <CardActionArea>
                        {type === 'full-video' ? (
                          <CardMedia component="video" height="140" controls src={ele?.video?.url} />
                        ) : (
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              type === 'wallpaper'
                                ? ele.image
                                : null ||
                                  type === 'wallpaperHindi' ||
                                  type === 'wallpaperEnglish' ||
                                  type === 'wallpaperDp'
                                ? ele?.image?.url
                                : null || type === 'sayariImage'
                                ? ele.shayari
                                : null || type === 'category'
                                ? ele?.image
                                : null || type === 'statusImage'
                                ? ele?.status
                                : null || type === 'jokeImage'
                                ? ele.joke
                                : null
                            }
                            alt="green iguana"
                          />
                        )}
                        <CardContent sx={{ padding: '12px 15px' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography gutterBottom variant="h5" component="div">
                              {type === 'video-status' ||
                              type === 'full-video' ||
                              type === 'statusImage' ||
                              type === 'sayariImage' ||
                              type === 'jokeImage'
                                ? ele.title
                                : ele.name}
                            </Typography>
                            {type === 'wallpaper' ? (
                              <Typography gutterBottom variant="h5" component="div">
                                {ele.isPremium === 'true' ? (
                                  <WorkspacePremiumIcon
                                  // className={modulesStyle["premium-icons"]}
                                  />
                                ) : null}
                              </Typography>
                            ) : null}
                          </div>
                          {type === 'wallpaper' ? (
                            <Typography gutterBottom variant="body" component="div" sx={{ fontWeight: 'bold' }}>
                              Colour:
                              {ele.colour.length === 1
                                ? ele.colour[0]?.name
                                : ele.colour.map((e) => {
                                    return e.name + ' , ';
                                  })}
                            </Typography>
                          ) : null}
                          {type === 'category' ||
                          type === 'wallpaperHindi' ||
                          type === 'wallpaperEnglish' ||
                          type === 'wallpaperDp' ? (
                            <Typography gutterBottom variant="body" component="div">
                              Type:
                              {type === 'category' ||
                              type === 'wallpaper' ||
                              type === 'wallpaperHindi' ||
                              type === 'wallpaperEnglish' ||
                              type === 'wallpaperDp'
                                ? ele?.type
                                : null}
                            </Typography>
                          ) : null}
                          {type === 'category' ? (
                            <Typography gutterBottom variant="body" component="div">
                              Count: {type === 'category' ? ele?.count : null}
                            </Typography>
                          ) : null}
                          {type === 'video-status' || type === 'full-video' ? (
                            <Typography gutterBottom variant="body" component="div" sx={{ fontWeight: 'bold' }}>
                              Like:- {type === 'video-status' || type === 'full-video' ? ele.like : ele.name}
                            </Typography>
                          ) : null}
                          {type === 'video-status' || type === 'full-video' ? (
                            <Typography gutterBottom variant="body" component="div" sx={{ fontWeight: 'bold' }}>
                              View:- {type === 'video-status' || type === 'full-video' ? ele.view : ele.name}
                            </Typography>
                          ) : null}
                          {type === 'video-status' || type === 'wallpaper' ? (
                            <Typography gutterBottom variant="body" component="div" sx={{ fontWeight: 'bold' }}>
                              Category:-
                              {type === 'video-status'
                                ? ele.category?.name
                                : type === 'wallpaper' && ele?.category.length === 1
                                ? ele.category[0]?.name
                                : ele.category.map((e) => {
                                    return e.name + ' , ';
                                  })}
                            </Typography>
                          ) : null}
                          <IconButton aria-label="edit" color="primary">
                            <EditIcon
                              onClick={() => {
                                handleOpen(ele);
                              }}
                            />
                          </IconButton>
                          <IconButton aria-label="edit" color="primary">
                            <DeleteIcon
                              onClick={() => {
                                handleDelete(ele._id);
                              }}
                            />
                          </IconButton>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })
            : 'No data found' && null}

          {isCardSelect && getType && getType.length > 0
            ? getType.map((ele) => {
                return (
                  <>
                    <Grid item xs={3}>
                      <Card sx={{ maxWidth: 345, marginTop: 5 }}>
                        <CardActionArea>
                          {type === 'category' ? (
                            <CardMedia
                              component="img"
                              height="140"
                              image={type === 'category' ? ele?.image : null}
                              alt="green iguana"
                            />
                          ) : null}
                          <CardContent sx={{ padding: '12px 15px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography gutterBottom variant="h5" component="div">
                                {type === 'category' ? ele.name : null}
                              </Typography>
                            </div>
                            <Typography gutterBottom variant="body" component="div">
                              Type: {type === 'category' ? ele?.type : null}
                            </Typography>
                            <IconButton aria-label="edit" color="primary">
                              <EditIcon
                                onClick={() => {
                                  handleOpen(ele);
                                }}
                              />
                              <MenuItem>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              </MenuItem>
                            </IconButton>

                            <IconButton aria-label="edit" color="primary">
                              <DeleteIcon
                                onClick={() => {
                                  handleDelete(ele._id);
                                }}
                              />
                            </IconButton>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </>
                );
              })
            : (
                <>
                  <div>
                    <h3 style={{ textAlign: 'center' }}>No Data</h3>
                  </div>
                </>
              ) && null}
        </>
      </Grid>
      {type === 'category' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <CategoryForm singleData={singleData} isEdit handleClose={handleClose} getAllCategory={getAllCategory} />
        </Modal>
      ) : null}

      {type === 'wallpaper' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <WallpaperForm
            singleData={singleData}
            isImageEdit
            handleClose={handleClose}
            getAllWallpaper={getAllWallpaper}
          />
        </Modal>
      ) : null}

      {type === 'wallpaperHindi' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <WallpaperHindiForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllWallpaperHindi={getAllWallpaperHindi}
          />
        </Modal>
      ) : null}

      {type === 'wallpaperDp' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <WallpaperDpForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllWallpaperDp={getAllWallpaperDp}
          />
        </Modal>
      ) : null}

      {type === 'wallpaperEnglish' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <WallpaperEnglishForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllWallpaperEnglish={getAllWallpaperEnglish}
          />
        </Modal>
      ) : null}

      {type === 'statusImage' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <StatusImageForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllStatusImage={getAllStatusImage}
          />
        </Modal>
      ) : null}

      {type === 'jokeImage' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <JokeImageForm singleData={singleData} isEdit handleClose={handleClose} getAllJokesImage={getAllJokesImage} />
        </Modal>
      ) : null}

      {type === 'sayariImage' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <SayariImageForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllSayariImage={getAllSayariImage}
          />
        </Modal>
      ) : null}

      {type === 'video-status' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <VideoStatusForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllVideoStatus={getAllVideoStatus}
          />
        </Modal>
      ) : null}
      {type === 'full-video' ? (
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <FullScreenVideoForm
            singleData={singleData}
            isEdit
            handleClose={handleClose}
            getAllVideoStatus={getAllVideoStatus}
          />
        </Modal>
      ) : null}
    </>
  );
}
