import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Modal, Fade, Box, Typography,Backdrop,Button } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
// import CategoryModal from '../../../components/Ca';
import { apiInstance } from '../../../httpClient';
import SayariForm from '../../../components/SayariForm';
import StatusForm from '../../../components/StatusForm';
import LanguageForm from '../../../components/LanguageForm';
import JokesForm from 'src/components/JokesForm';
import SayariFormHindi from 'src/components/SayariHindiForm';
import SayariFormEnglish from 'src/components/SayariEnglishForm';
import StatusHindiForm from 'src/components/StatusHindiForm';
import StatusEnglishForm from 'src/components/StatusEnglishForm';
import JokesFormHindi from 'src/components/JokesHindiForm';
import JokesFormEnglish from 'src/components/JokesEnglishForm';
import QuotesHindiForm from 'src/components/QuotesEnglishForm';
import ColorForm from 'src/components/ColorForm';
import IndustryForm from 'src/components/IndustryForm';
import AuthorFormPage from 'src/components/AuthorForm';
import CityForm from 'src/components/CityForm';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import crossIcon from '../../../assets/roundShape.png'
import EventForm from 'src/components/EventForm';
// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:"10px"
  // paddingTop: '0px',
};

export default function UserMoreMenu({
  type,
  data,
  getSayariData,
  getAllColor,
  showQuotes,
  getJokesDataHindi,
  getJokesDataEnglish,
  getStatusEnglish,
  getStatusHindi,
  getSayariDataEnglish,
  getSayariDataHindi,
  getJokesData,
  getStatusData,
  getLanguageList,
  showCategory,
  showAuthor,
  showQuotesImage,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteId,setDeleteId] = useState('')

  const handleOpenModal = (data) => {
    setDeleteId(data?._id)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpen = (data) => {
    setNewData(data);
    setOpen(true);
    setIsOpen(false);
  };


  const handleBlock = async (data) => {
    try {
      const res = await apiInstance.post(`/user/block/${data._id}`);
      showQuotes();
      handleClose();
    } catch (e) {}
    setNewData(data);
    setOpen(true);
    setIsOpen(false);
  }

  const handleUnblock = async (data) => {
    try {
      const res = await apiInstance.post(`/user/unblock/${data._id}`);
      console.log("------",res);
      showQuotes();
      handleClose();
    } catch (e) {}
    setNewData(data);
    setOpen(true);
    setIsOpen(false);
  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (type === 'sayari') {
      try {
        const res = await apiInstance.delete(`shayarivs/delete?shayariId=${deleteId}`);
        handleClose();
        getSayariData();
      } catch (e) {}
    }
    if (type === 'status') {
      try {
        const res = await apiInstance.delete(`statusvs/delete?statusId=${deleteId}`);
        handleClose();
        getStatusData();
      } catch (e) {}
    }
    if (type === 'language') {
      try {
        const res = await apiInstance.delete(`languagevs/delete?languageId=${deleteId}`);
        handleClose();
        getLanguageList();
      } catch (e) {}
    }
    if (type === 'Jokes') {
      try {
        const res = await apiInstance.delete(`jokevs/delete/?jokeId=${deleteId}`);
        handleClose();
        getJokesData();
      } catch (e) {}
    }
    if (type === 'JokesHindi') {
      try {
        const res = await apiInstance.delete(`jokevs/delete/?jokeId=${deleteId}`);
        handleClose();
        getJokesDataHindi();
      } catch (e) {}
    }
    if (type === 'JokesEnglish') {
      try {
        const res = await apiInstance.delete(`jokevs/delete/?jokeId=${deleteId}`);
        handleClose();
        getJokesDataEnglish();
      } catch (e) {}
    }
    if (type === 'sayariHindi') {
      try {
        const res = await apiInstance.delete(`/tag/delete/${deleteId}`);
        handleClose();
        getSayariDataHindi();
      } catch (e) {}
    }
    if (type === 'sayariEnglish') {
      try {
        const res = await apiInstance.delete(`shayarivs/delete/?shayariId=${deleteId}`);
        handleClose();
        getSayariDataEnglish();
      } catch (e) {}
    }
    if (type === 'statusHindi') {
      try {
        const res = await apiInstance.delete(`statusvs/delete?statusId=${deleteId}`);
        handleClose();
        getStatusHindi();
      } catch (e) {}
    }
    if (type === 'statusEnglish') {
      try {
        const res = await apiInstance.delete(`statusvs/delete?statusId=${deleteId}`);
        handleClose();
        getStatusEnglish();
      } catch (e) {}
    }
    if (type === 'quotesEnglish') {
      try {
        const res = await apiInstance.delete(`/user/block//${deleteId}`);
        handleClose();
        showQuotes();
      } catch (e) {}
    }
    if (type === 'color') {
      try {
        const res = await apiInstance.delete(`colour/delete/${deleteId}`);
        handleClose();
        getAllColor();
      } catch {}
    }
    if (type === 'motivateCategory') {
      try {
        const res = await apiInstance.delete(`/industry/delete/${deleteId}`);
        handleClose();
        showCategory();
      } catch {}
    }
    if (type === 'author') {
      try {
        const res = await apiInstance.delete(`authormotiv/${deleteId}`);
        handleClose();
        showAuthor();
      } catch {}
    }
    if (type === 'quotesImage') {
      try {
        const res = await apiInstance.delete(`/event/delete/${deleteId}`);
        handleClose();
        showQuotesImage();
      } catch {}
    }
    if (type === 'myAffirmationCategory') {
      try {
        const res = await apiInstance.delete(`/city/delete/${deleteId}`);
        handleCloseModal();
        handleClose();
        showCategory();
      } catch {}
    }
    if (type === 'MyAffirmationQuotes') {
      try {
        const res = await apiInstance.delete(`quotesmyaff/${deleteId}`);
        handleClose();
        handleCloseModal();
        showQuotes();
      } catch {}
    }
    if (type === 'MyAffirmationQuotesImage') {
      try {
        const res = await apiInstance.delete(`quotimagemyaff/${deleteId}`);
        handleClose();
        showQuotesImage();
      } catch {}
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {type === "quotesEnglish"?null:<MenuItem sx={{ color: 'text.secondary' }} onClick={() =>handleOpenModal(data)}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {type === "quotesEnglish"?null:<MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleOpen(data)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {type === "quotesEnglish"?
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleBlock(data)}>
          <ListItemIcon>
            <Iconify icon="material-symbols:block" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Block" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>:null}
        {type === "quotesEnglish"?
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleUnblock(data)}>
          <ListItemIcon>
            <Iconify icon="gg:unblock" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Unblock" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>:null}
      </Menu>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box sx={style}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ClearRoundedIcon onClick={handleCloseModal} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={crossIcon} alt="delete icon" width={110} />
              </div>
              <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                Are you sure?
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 1, textAlign: 'center', fontSize: '14px' }}>
                Do you really want to delete these records? this process cannot be undone
              </Typography>
              <Button variant="outlined" sx={{ mt: 1 }} onClick={handleDelete}>
                Confirm
              </Button>
            </Box>
          </Fade>
        </Modal>
      </div>

      {type === 'sayari' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SayariForm isEdit singleData={newData} handleClose={handleClose} getSayariData={getSayariData} />
        </Modal>
      ) : null}

      {type === 'sayariHindi' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SayariFormHindi
            isEdit
            singleData={newData}
            handleClose={handleClose}
            getSayariDataHindi={getSayariDataHindi}
          />
        </Modal>
      ) : null}

      {type === 'sayariEnglish' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SayariFormEnglish
            isEdit
            singleData={newData}
            handleClose={handleClose}
            getSayariDataEnglish={getSayariDataEnglish}
          />
        </Modal>
      ) : null}

      {type === 'status' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StatusForm isEdit singleData={newData} handleClose={handleClose} getStatusData={getStatusData} />
        </Modal>
      ) : null}

      {type === 'statusHindi' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StatusHindiForm isEdit singleData={newData} handleClose={handleClose} getStatusHindi={getStatusHindi} />
        </Modal>
      ) : null}

      {type === 'statusEnglish' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StatusEnglishForm
            isEdit
            singleData={newData}
            handleClose={handleClose}
            getStatusEnglish={getStatusEnglish}
          />
        </Modal>
      ) : null}

      {type === 'language' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <LanguageForm isEdit singleData={newData} handleClose={handleClose} getLanguageList={getLanguageList} />
        </Modal>
      ) : null}

      {type === 'Jokes' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <JokesForm isEdit singleData={newData} handleClose={handleClose} getJokesData={getJokesData} />
        </Modal>
      ) : null}

      {type === 'JokesHindi' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <JokesFormHindi isEdit singleData={newData} handleClose={handleClose} getJokesDataHindi={getJokesDataHindi} />
        </Modal>
      ) : null}

      {type === 'JokesEnglish' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <JokesFormEnglish
            isEdit
            singleData={newData}
            handleClose={handleClose}
            getJokesDataEnglish={getJokesDataEnglish}
          />
        </Modal>
      ) : null}

      {/* {type === 'quotesEnglish' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <QuotesHindiForm isEdit singleData={newData} handleClose={handleClose} showQuotes={showQuotes} />
        </Modal>
      ) : null} */}

      {type === 'color' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ColorForm isEdit singleData={newData} handleClose={handleClose} getAllColor={getAllColor} />
        </Modal>
      ) : null}

      {type === 'motivateCategory' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <IndustryForm isEdit singleData={newData} handleClose={handleClose} showCategory={showCategory} />
        </Modal>
      ) : null}

      {type === 'author' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AuthorFormPage isEdit singleData={newData} handleClose={handleClose} showAuthor={showAuthor} />
        </Modal>
      ) : null}
      {type === 'quotesImage' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EventForm isEdit singleData={newData} handleClose={handleClose} showQuotesImage={showQuotesImage} />
        </Modal>
      ) : null}
      {type === 'myAffirmationCategory' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CityForm
            isEdit
            singleData={newData}
            handleClose={handleClose}
            showCategory={showCategory}
          />
        </Modal>
      ) : null}
      {type === 'MyAffirmationQuotes' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EventForm isEdit singleData={newData} handleClose={handleClose} showQuotes={showQuotes} />
        </Modal>
      ) : null}

      {type === 'MyAffirmationQuotesImage' ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CityForm
            isEdit
            singleData={newData}
            handleClose={handleClose}
            showQuotesImage={showQuotesImage}
          />
        </Modal>
      ) : null}
    </>
  );
}
