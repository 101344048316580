import { useState } from 'react';
import InputColor from 'react-input-color';

function ColorButton({ onChange, hashCode }) {
    return (
        <div style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
            <div>
                <InputColor
                    initialValue={hashCode ? hashCode : "#5e72e4"}
                    onChange={onChange}
                    placement="right"
                />
            </div>
        </div>
    );
}

export default ColorButton
