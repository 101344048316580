// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const icons = (name) => <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />;


const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Category',
  //   path: '/dashboard/category',
  //   icon: icons('menu'),
  // },
  {
    title: 'Industries',
    path: '/dashboard/industry',
    icon: icons('menu'),
  },
  {
    title: 'City',
    path: '/dashboard/city',
    icon: icons('menu'),
  },
  {
    title: 'Users',
    path: '/dashboard/userlisting',
    icon: icons('menu'),
  },
  {
    title: 'Events',
    path: '/dashboard/eventpage',
    icon: icons('contract'),
  },
  {
    title: 'Tags',
    path: '/dashboard/role',
    icon: icons('contract'),
  },
  // {
  //   title: 'Sayari-English',
  //   path: '/dashboard/sayariEnglish',
  //   icon: icons('contract'),
  // },
  // {
  //   title: 'Sayari-Image',
  //   path: '/dashboard/sayariImage',
  //   icon: icons('contract'),
  // },
  // {
  //   title: 'Language',
  //   path: '/dashboard/language',
  //   icon: icons('language'),
  // },
  // {
  //   title: 'Status',
  //   path: '/dashboard/status',
  //   icon: icons('status'),
  // },
  // {
  //   title: 'Status-Hindi',
  //   path: '/dashboard/statusHindi',
  //   icon: icons('status'),
  // },
  // {
  //   title: 'Status-English ',
  //   path: '/dashboard/statusEnglish',
  //   icon: icons('status'),
  // },
  // {
  //   title: 'Status-image',
  //   path: '/dashboard/statusImage',
  //   icon: icons('status'),
  // },
  // {
  //   title: 'Wallpaper',
  //   path: '/dashboard/wallpaper',
  //   icon: icons('picture'),
  // },
  // {
  //   title: 'Wallpaper Dp',
  //   path: '/dashboard/wallpaperDp',
  //   icon: icons('picture'),
  // },
  // {
  //   title: 'Wallpaper-Hindi',
  //   path: '/dashboard/wallpaperHindi',
  //   icon: icons('picture'),
  // },
  // {
  //   title: 'Wallpaper-English',
  //   path: '/dashboard/wallpaperEnglish',
  //   icon: icons('picture'),
  // },
  // {
  //   title: 'Video Status',
  //   path: '/dashboard/video-status',
  //   icon: icons('video'),
  // },
  // {
  //   title: 'Full Video',
  //   path: '/dashboard/full-video',
  //   icon: icons('video'),
  // },
  // {
  //   title: 'Jokes',
  //   path: '/dashboard/jokes',
  //   icon: icons('joking'),
  // },
  // {
  //   title: 'Jokes-Hindi',
  //   path: '/dashboard/jokesHindi',
  //   icon: icons('joking'),
  // },
  // {
  //   title: 'Jokes-English',
  //   path: '/dashboard/jokesEnglish',
  //   icon: icons('joking'),
  // },
  // {
  //   title: 'Jokes-Image',
  //   path: '/dashboard/jokesImage',
  //   icon: icons('joking'),
  // },
  // {
  //   title: 'Quotes English',
  //   path: '/dashboard/quotes',
  //   icon: icons('quotes'),
  // },  
  // {
  //   title: 'Quotes Image',
  //   path: '/dashboard/quotesImage',
  //   icon: icons('quotes'),
  // },
  // {
  //   title: 'MyAffirmation QuotesImage',
  //   path: '/dashboard/myAffirmationQuotesImage',
  //   icon: icons('quotes'),
  // },
  // {
  //   title: 'MyAffirmation Quotes',
  //   path: '/dashboard/myAffirmationQuotes',
  //   icon: icons('quotes'),
  // },
  // {
  //   title: 'Author',
  //   path: '/dashboard/author',
  //   icon: icons('quotes'),
  // },
];

export default navConfig;
