// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidget({ title, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        paddingTop:'12px',
        paddingBottom:'0px',
        borderRadius:'5px',
        height:'45px',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="body" sx={{ opacity: 0.72 }}>
       {`${title} `}  {" "}  {" "}  {" | "}  <span style={{fontWeight:'bolder',fontSize:'15px',paddingTop:'5px'}}>{`${total}`}</span>
      </Typography>
      {/* <Typography variant="h3">{total}</Typography> */}


    </Card>
  );
}
