import OtpTimer from "otp-timer";

const Otp = ({ NewOtp }) => {
    const submit = () => {
        NewOtp()
    };
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2%', color: 'black', fontFamily: 'Poppins' }}>
                <OtpTimer
                    minutes={1}
                    seconds={59}
                    text="Time:"
                    ButtonText="Resend"
                    resend={submit}
                />
            </div>
        </>
    )
}

export default Otp;