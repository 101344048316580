
import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const UploadAndDisplayImage = ({ onChange, file, singleData, }) => {
  return (
    <>
      <br />
      <div>
        <label htmlFor="contained-button-file" style={{ display: "flex", alignItems: "start" }}>
          <Input accept="image/*" id="contained-button-file" onChange={onChange} multiple type="file" />
          <div>
            <Button variant="contained" component="span" sx={{ borderRadius: 0 }}>
              Upload Image
            </Button>
          </div>
        </label>
      </div>
      {file ?
        <div >
          <img src={file} alt='' style={{ paddingTop: 10, width: 300, height: 150, }} />
        </div> : singleData ? <img src={singleData?.image} alt='img' style={{ width: 300, height: 150, paddingTop: 10 }} /> : null}
    </>
  );
};
export default UploadAndDisplayImage;