import { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
// import { apiInstance } from '../httpClient/httpClient';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const SayariForm = ({ handleClose, getSayariData, isEdit, singleData }) => {

  const [name, setName] = useState(singleData ? singleData.shayari : '');
  const [type, setType] = useState(singleData ? singleData.type : '');
  const [category, setCategory] = useState(singleData ? singleData.category?._id : '');
  const [loader, setLoader] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  // const [sayariData, setSayariData] = useState([]);

  useEffect(() => {
    getAllCategory()
  }, [])

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('categoryvs/get?type=Shayari');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) {

    }
  };

  const handleAddSayari = async () => {
    setLoader(true);
    const reqObj = {
      shayari: name,
      type: type,
      category: category,
      image: false,
      shayariId: isEdit ? singleData?._id : '',
    };
    if (isEdit) {
      try {
        const res = await apiInstance.put('shayarivs/update', reqObj);
        
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getSayariData();
        }
      } catch (error) {

        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('shayarivs/create', reqObj);
        
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getSayariData();
        }
      } catch (error) {

        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 className="m-0">{isEdit ? 'Edit Sayari' : 'Add Sayari'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
        </Stack>

        <Stack spacing={2}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="Latest">Latest</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Stack>
        <Stack spacing={2} sx={{ marginTop: '3%' }}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Type"
                onChange={handleCategory}
              >
                {categoryData &&
                  categoryData.map((ele) => {
                    return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                  })}
              </Select>
              {/* <span style={{ color: '#f00' }}>{errors ? errors.category : ''}</span> */}
            </FormControl>
          </Grid>
        </Stack>

        {/* <Grid container spacing={3} sx={{ marginTop: 1 }}>
          
         
        </Grid> */}
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddSayari}
          disableElevation
        >
          {isEdit ? 'Update' : 'Add Sayari'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default SayariForm;
