import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
// import { Link, Stack, Checkbox, TextField, IconButton, FormControlLabel, InputLabel,InputAdornment  } from '@mui/material';
import { Box, InputLabel, FormControl, InputAdornment, Input, IconButton, Button, Typography } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import OtpInput from 'react-otp-input';
import OtpTimer from '../../../components/OtpTimer'
import { apiInstance } from 'src/httpClient';
import { CircularProgress } from '@mui/material';
import {getForgotToken, getToken, setForgotTokenSession} from '../../../utils/common';
// ----------------------------------------------------------------------
export default function OtpForm(props) {
  
  
  const {state} = useLocation();
  
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    setOtp(e);
  };


  const NewOtp = async () =>{
    
    const AddOtp = {
      email: state.email,
      otp:otp
    }
    
    setLoader(true);

    
    try{
      
      const response = await apiInstance.post('auth/set-otp-admin', AddOtp,{
        headers: {
          'X-auth-token':getForgotToken(),
        }
      });
      
      if(response.status === 200){
        
        setForgotTokenSession(response.data.data.token)
        setLoader(false);
        navigate('/reset',{state:{
          email:state.email
        }});
      }
      
    }catch(error){
      
      setLoader(false);
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap',justifyContent:'center' }}>
        <OtpInput
          inputStyle={{
            width: '3rem',
            height: '3rem',
            fontSize: '1rem',
            borderRadius: 4,
            border: '2px solid black',
            // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
          onChange={handleChange}
          numInputs={4}
          separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>} 
          value={otp}
        />
       
      </Box>
          <Button variant="contained"  className="register-here"  sx={{ textTransform: 'none',display:'flex',height:'45px',mt:2 }} onClick={NewOtp}  >
            Verify
           {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
          </Button>
        <OtpTimer NewOtp={NewOtp} />
    </>
  );
}
