import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
  FormLabel,
  Chip,
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const MyAffirmationQuotesImageForm = ({ handleClose, isEdit, singleData, showQuotesImage }) => {
  const [category, setCategory] = useState(singleData ? singleData.category?._id : '');
  const [file, setFile] = useState('');
  const [uploadFile, setUploadFile] = useState('');
  const inputRef = React.useRef(null);
  const [categoryData, setCategoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState('typing');

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('categorymyaff');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) {}
  }

  if (status === 'success') {
    return <h1>That's right!</h1>;
  }

  const AddQuotesImage = async () => {
    setLoader(true);
    setStatus('submitting');
    const formData = new FormData();
    formData.append('categoryId', category);
    formData.append('image', uploadFile);
    if (isEdit) {
      try {
        const res = await apiInstance.put(`quotimagemyaff/${singleData?._id}`, formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showQuotesImage();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('quotimagemyaff', formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showQuotesImage();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    }
  };
  const handleUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>{isEdit ? 'Edit MyAffirmation QuotesImage' : 'Add MyAffirmation QuotesImage'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Stack spacing={2} sx={{ marginTop: '3%' }}>
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Please select author"
                  value={category}
                  label="Category"
                  onChange={handleCategory}
                >
                  {categoryData &&
                    categoryData.map((ele) => {
                      return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Stack>
          <Stack sx={{ marginTop: '10px' }}>
            <Grid container item xs={12}>
              <FormLabel>File</FormLabel>
              <FormControl variant="outlined" fullWidth>
                {!file ? (
                  <>
                    <input
                      name="file"
                      hidden
                      type="file"
                      accept=".jpeg,.jpg,.mp4,.avi,.png,.webp"
                      multiple={false}
                      ref={inputRef}
                      onChange={handleUpload}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      Choose image
                    </Button>
                  </>
                ) : (
                  <>
                    <Chip label={file?.name || 'File'} />
                  </>
                )}
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto',
                  }}
                >
                  {file ? (
                    <img src={file} alt="img" style={{ height: '100px', width: '100px' }} />
                  ) : singleData && singleData?.image ? (
                    <img src={singleData?.image[0]} alt="img" style={{ height: '100px', width: '100px' }} />
                  ) : (
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#c5c5c5',
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '15px',
                      }}
                    >
                      <p>
                        Image <br /> Preview
                      </p>
                    </div>
                  )}
                </div>
              </FormControl>
            </Grid>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%', height: '100%' }}
          onClick={AddQuotesImage}
          disabled={uploadFile.length === 0 || status === 'submitting'}
        >
          {isEdit ? 'Update MyAffirmation QuotesImage' : 'Add MyAffirmation QuotesImage'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default MyAffirmationQuotesImageForm;
