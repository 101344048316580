import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import {CircularProgress} from '@mui/icons-material'
import { CircularProgress } from '@mui/material';
import ColorButton from '../components/ColorCode';
import { apiInstance } from 'src/httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 755,
  height: "auto",
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  padding: '24px',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ColorForm = ({ handleClose, getAllColor, isEdit, singleData }) => {
  const [hashCode, setHashCode] = useState(singleData?.hashCode || '');
  const [name, setName] = useState(singleData?.name || '');
  const [loader, setLoader] = useState(false);
  const [color, setColor] = useState({});
  const [errors, setError] = useState({
    name: '',
    hashCode: '',
    // fileData: '',
  });

  const newColor = async () => {
    if (!name.length && !hashCode.length) {
      setError({ ...errors, name: 'Color is requried', hashCode: 'HexCode is requried' });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'Color is requried' });
      return;
    }
    if (!hashCode) {
      setError({ ...errors, hashCode: 'HexCode is requried' });
    }
    if (name && hashCode) {
      setError({ name: '', hashCode: '' });
    }
    const addColor = {
      hashCode: color ? color.hex : hashCode,
      name: name,
    };

    setLoader(true);
    if (isEdit) {
      try {
        const response = await apiInstance.put(`colour/${singleData?._id}`, addColor);
        setLoader(false);
        getAllColor();
        handleClose();
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const response = await apiInstance.post('colour', addColor);
        setLoader(false);
        getAllColor();
        handleClose();
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isEdit ? 'Edit Color' : 'Add Color'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <CloseRoundedIcon onClick={isEdit ? handleClose : handleClose} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid container item xs={12}>
            <TextField
              label={'Color Name'}
              placeholder='Enter Color Name'
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
          <Grid container item xs={12}>
            {/* <ButtonExample/> */}
            <Grid container item xs={11}>
              {isEdit ? (
                <TextField
                  label={'Hex code'}
                  id="name"
                  type="text"
                  style={{ width: '100%', height: '100%' }}
                  onChange={(e) => {
                    setHashCode(e.target.value);
                  }}
                  value={color.hex}
                />
              ) : (
                <TextField
                  label={'Hex code'}
                  id="name"
                  type="text"
                  style={{ width: '100%', height: '100%' }}
                  onChange={(e) => {
                    setHashCode(e.target.value);
                  }}
                  value={hashCode ? hashCode : color.hex}
                />
              )}
              <span style={{ color: '#f00' }}>{errors ? errors.hashCode : ''}</span>
            </Grid>
            <Grid container item xs={1}>
              <ColorButton hashCode={hashCode} onChange={setColor} />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ width: '100%', height: '15%', marginTop: '3%' }}
          disableElevation
          onClick={newColor}
        >
          {isEdit ? 'Edit color' : 'Add color'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default ColorForm;
