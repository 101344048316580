import { useState, useEffect, React } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import reactImageSize from 'react-image-size';
import UploadImage from '../components/UploadImage';
import { CircularProgress, FormControlLabel, Switch } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { apiInstance } from 'src/httpClient';
import { MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: '24px',
  // overflow: 'scroll'
  overflowY: 'auto',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 435,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const WallpaperForm = ({ handleClose, getAllWallpaper, isImageEdit, singleData }) => {
  const [name, setName] = useState(singleData?.name || '');
  const [category, setCategory] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [resolition, setResolition] = useState(
    singleData?.resolution || {
      width: '-',
      height: '-',
    }
  );
  const [size, setSize] = useState(singleData?.size || '');
  const [isPremium, setIsPremium] = useState(singleData?.isPremium === 'true' ? true : false || false);
  const [loader, setLoader] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [file, setFile] = useState(null);
  const [post, setPost] = useState([]);
  const [all, setAll] = useState([]);
  const [errors, setError] = useState({
    name: '',
    fileData: '',
    category: '',
    uploadFile: '',
    personName: '',
  });

  const theme = useTheme();

  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === 'string' ? value.split(',') : value);
  };

  const handlePremium = async (event) => {
    setIsPremium(event.target.checked);
  };

  const newImage = async () => {
    if (!name.length && !personName.length && !category.length && !uploadFile.length) {
      setError({
        ...errors,
        name: 'name is requried',
        personName: 'color is requried',
        category: 'category is requried',
        uploadFile: 'image is requried',
      });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'name is requried' });
      return;
    }
    if (!personName) {
      setError({ ...errors, personName: 'color is requried' });
    }
    if (!category) {
      setError({ ...errors, category: 'category is requried' });
    }
    if (!uploadFile) {
      setError({ ...errors, uploadFile: 'image is requried' });
    }
    if (name && personName && category && uploadFile) {
      setError({ name: '', type: '', category: '', uploadFile: '', personName: '' });
    }
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', uploadFile);
    formData.append('category', category);
    formData.append('colour', personName);
    formData.append('isPremium', isPremium.toString());
    formData.append('width', resolition.width);
    formData.append('height', resolition.height);
    if (uploadFile?.size && uploadFile?.size !== '') {
      formData.append('size', uploadFile?.size);
    }
    for (var pair of formData.entries()) {
    }
    setLoader(true);
    if (isImageEdit) {
      try {
        const response = await apiInstance.put(`image/${singleData?._id}`, formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });
        setLoader(false);
        getAllWallpaper();
        handleClose();
      } catch (error) {
        setLoader(false);
        handleClose();
      }
    } else {
      try {
        const response = await apiInstance.post('image', formData, {
          headers: {
            // 'X-auth-token': getToken(),
            'Content-Type': 'multipart/form-data',
          },
        });

        setLoader(false);
        handleClose();
        getAllWallpaper();
      } catch (error) {
        setLoader(false);
      }
    }
  };

  const handleUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
    setSize(e.target.files[0].size);
    setTimeout(() => {
      // reactImageSize(URL.createObjectURL(e.target.files[0]))
      //   .then((imgData) => {
      //     setResolition(imgData);
      //     // setImgReso(imgData)
      //   })
      //   .catch((errorMessage) => console.log('errorMessage', errorMessage));
    }, 1000);
  };

  useEffect(() => {
    getCategory();
    getAllColor();
  }, []);

  const getCategory = async () => {
    try {
      const response = await apiInstance.get('categoryvs/get?type=Stock-Image');
      setPost(response.data.data);
    } catch (error) {}
  };

  const getAllColor = async () => {
    try {
      const res = await apiInstance.get('colour/get-all');
      setAll(res.data.data);
    } catch (error) {}
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isImageEdit ? 'Edit Wallpaper' : 'Add Wallpaper'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <CloseRoundedIcon onClick={isImageEdit ? handleClose : handleClose} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid container item xs={6}>
            <FormControlLabel
              control={<Switch onChange={handlePremium} value={isPremium} checked={isPremium} />}
              label="Premium"
            />
          </Grid>
          <Grid container item xs={6} flexDirection="column">
            <UploadImage onChange={handleUpload} uploadFile={uploadFile} file={file} singleData={singleData} />
            <span style={{ color: '#f00' }}>{errors ? errors.uploadFile : ''}</span>
          </Grid>
          <Grid container item xs={6}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={'Name'}
              id="Name"
              type="text"
              value={name}
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
          <Grid container item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">Category</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={category}
                name="first"
                onChange={handleChangeCategory}
                input={<OutlinedInput label="Tag" />}
                SelectProps={{
                  multiple: true,
                }}
                fullWidth
                style={{ width: '100%', height: '100%' }}
              >
                {post.map((ele) => (
                  <MenuItem value={ele?._id}>{ele?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">Color</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={personName}
                name="first"
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                SelectProps={{
                  multiple: true,
                }}
                fullWidth
                style={{ width: '100%', height: '100%' }}
              >
                {all.map((ele) => (
                  <MenuItem value={ele?._id}>{ele?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6}>
            <TextField
              label={'Resolition'}
              id="Resolition"
              type="text"
              onChange={(e) => {
                setResolition(e.target.value);
              }}
              disabled
              // value={imgReso?.width +' * ' + imgReso?.height }
              value={resolition ? resolition?.width + ' * ' + resolition?.height : '-'}
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              label={'Size'}
              id="Size"
              type="text"
              onChange={(e) => {
                setSize(e.target.value);
              }}
              value={size}
              style={{ width: '100%', height: '100%' }}
              disabled
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ width: '100%', height: '10%', marginTop: '3%' }}
          onClick={newImage}
          disableElevation
        >
          {isImageEdit ? 'Edit image' : 'Add image'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default WallpaperForm;
