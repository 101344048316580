import { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
  Divider,
} from '@mui/material';

// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';

// import { apiInstance } from '../httpClient/httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const StatusHindiForm = ({ handleClose, isEdit, singleData, getStatusHindi }) => {
  const [name, setName] = useState(singleData ? singleData.status : '');
  const [type, setType] = useState(singleData ? singleData.type : '');
  const [category, setCategory] = useState(singleData ? singleData.category?._id : '');
  const [categoryData, setCategoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors, setError] = useState({
    name: '',
    type: '',
    category: '',
  });
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('categoryvs/get?type=Status-Hindi');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) {}
  };

  const handleAddStatus = async () => {
    if (!name.length && !type.length && !category.length) {
      setError({
        ...errors,
        name: 'name is requried',
        type: 'type is requried',
        category: 'category is requried',
      });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'name is requried' });
      return;
    }
    if (!type) {
      setError({ ...errors, type: 'type is requried' });
    }
    if (!category) {
      setError({ ...errors, type: 'category is requried' });
    }
    if (name && type && category) {
      setError({ name: '', type: '', category: '' });
    }
    setLoader(true);
    const reqObj = {
      status: name,
      type: type,
      category: category,
      image: false,
      statusId: isEdit ? singleData?._id : '',
    };

    if (isEdit) {
      try {
        const res = await apiInstance.put('statusvs/update', reqObj);

        if (res.status === 200) {
          // setSayariData(res.data.data);
          setLoader(false);
          handleClose();
          getStatusHindi();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('statusvs/create', reqObj);
        if (res.status === 200) {
          // setSayariData(res.data.data);
          setLoader(false);
          handleClose();
          getStatusHindi();
          // getSayariData();
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>{isEdit ? 'Edit Status-Hindi' : 'Add Status-Hindi'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
          <Stack spacing={2} sx={{ marginTop: '3%' }}>
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Category"
                  onChange={handleCategory}
                >
                  {categoryData &&
                    categoryData.map((ele) => {
                      return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                    })}
                </Select>
                <span style={{ color: '#f00' }}>{errors ? errors.category : ''}</span>
              </FormControl>
            </Grid>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Language"
                onChange={handleChange}
              >
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.type : ''}</span>
            </FormControl>
          </Grid>
        </Stack>

        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddStatus}
          disableElevation
        >
          {isEdit ? 'Update' : 'Add Status '}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default StatusHindiForm;
