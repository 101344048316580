import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { InputLabel, Select, MenuItem, FormControl, Alert } from '@mui/material';
// import { CloseRoundedIcon } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient';
// import { apiInstance } from '../httpClient/httpClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const CategoryForm = ({ handleClose, getAllCategory, singleData, isEdit }) => {
  const [name, setName] = useState(singleData ? singleData.name : '');
  const [type, setType] = useState(singleData ? singleData?.type : '');
  const [file, setFile] = useState('');
  const [loader, setLoader] = useState(false);
  const [fileData, setFileDate] = useState('');
  const [errors, setError] = useState({
    name: '',
    type: '',
    fileData: '',
  });

  const Input = styled('input')({
    display: 'none',
  });

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleUploadFile = async (e) => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('title', name);
    formData.append('type', 'image');
    formData.append('files', e.target.files[0]);

    try {
      const res = await apiInstance.post('filesvs/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 200) {
        setFileDate(res.data.data);
      }
    } catch (error) {}
  };

  const handleAddCategory = async () => {
    if (!name.length && !type.length && !fileData.length) {
      setError({ ...errors, name: 'name is requried', type: 'type is requried', fileData: 'image is requried' });
      return;
    }
    if (!name) {
      setError({ ...errors, name: 'name is requried' });
      return;
    }
    if (!type) {
      setError({ ...errors, type: 'type is requried' });
    }
    if (!fileData) {
      setError({ ...errors, fileData: 'image is requried' });
    }
    if (name && type && fileData) {
      setError({ name: '', type: '', fileData: '' });
    }
    const redObj = {
      name: name,
      type: type,
      image: fileData?.url,
      cateoryId: isEdit ? singleData._id : '',
    };

    setLoader(true);
    if (isEdit) {
      try {
        const res = await apiInstance.put('categoryvs/update', redObj);
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllCategory();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('categoryvs/create', redObj);
        if (res.status === 200) {
          setLoader(false);
          handleClose();
          getAllCategory();
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3>{isEdit ? 'Edit Category' : 'Add category'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack>
          <Grid container item xs={12}>
            <TextField
              label={'Name'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              type="text"
              style={{ width: '100%', height: '100%' }}
            />
            <span style={{ color: '#f00' }}>{errors ? errors.name : ''}</span>
          </Grid>
        </Stack>
        <Stack sx={{ marginTop: '3%' }} spacing={2}>
          <Grid container item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={handleChangeType}
              >
                <MenuItem value="Video">Video</MenuItem>
                <MenuItem value="Wallpaper-English">Wallpaper-English</MenuItem>
                <MenuItem value="Wallpaper-Dp">Wallpaper-Dp</MenuItem>
                <MenuItem value="Wallpaper-Hindi">Wallpaper-Hindi</MenuItem>
                <MenuItem value="Wallpaper-Image">Wallpaper-Image</MenuItem>
                <MenuItem value="Shayari-English">Shayari-English</MenuItem>
                <MenuItem value="Shayari-Hindi">Shayari-Hindi</MenuItem>
                <MenuItem value="Status-English">Status-English</MenuItem>
                <MenuItem value="Status-Hindi">Status-Hindi</MenuItem>
                <MenuItem value="Jokes-English">Jokes-English</MenuItem>
                <MenuItem value="Jokes-Hindi">Jokes-Hindi</MenuItem>
                <MenuItem value="Quotes-English">Quotes-English</MenuItem>
                <MenuItem value="Stock-Image">Stock-Image</MenuItem>
                <MenuItem value="Stock-Ringtone">Stock-Ringtone</MenuItem>
              </Select>
              <span style={{ color: '#f00' }}>{errors ? errors.type : ''}</span>
            </FormControl>
          </Grid>
        </Stack>
        <Stack sx={{ marginTop: 2 }}>
          <Grid container item xs={12}>
            <form>
              <label htmlFor="contained-button-file" style={{ display: 'flex', alignItems: 'start' }}>
                <Input id="contained-button-file" multiple type="file" />
                <div>
                  <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
                    Image upload
                    <input hidden accept="image/*" multiple type="file" onChange={handleUploadFile} />
                  </Button>
                  <h4>{file ? file.name : ''}</h4>
                </div>
              </label>
              <div className="d-block">
                {fileData ? (
                  <img src={fileData?.url} alt="img" style={{ height: '100px', width: '100px' }} />
                ) : singleData && singleData.image ? (
                  <img src={singleData?.image} alt="img" style={{ height: '100px', width: '100px' }} />
                ) : null}
              </div>
              <span style={{ color: '#f00' }}>{errors ? errors.fileData : ''}</span>
            </form>
            <br />
          </Grid>
        </Stack>

        {/* <Grid container spacing={3} sx={{ marginTop: 1 }}>
          
         
        </Grid> */}
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%' }}
          onClick={handleAddCategory}
          disableElevation
        >
          {isEdit ? 'Update' : 'Add category'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box>
    </>
  );
};
export default CategoryForm;
