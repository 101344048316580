import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    CircularProgress,
    Grid,
} from '@mui/material';
// components
import { read, utils, writeFile } from 'xlsx';
import CategoryForm from '../components/CategoryForm';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { ProductList } from '../sections/@dashboard/products';
import PRODUCTS from '../_mock/products';
import WallpaperForm from '../components/WallpaperForm';
import { apiInstance } from '../httpClient';
import { styled } from '@mui/material/styles';
import WallpaperHindiForm from 'src/components/WallpeprHindiForm';
import AppCommonSummary from 'src/sections/@dashboard/app/AppCommonSqure';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'src/utils/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'company', label: 'Company', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function WallpeprHindi() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [data, setData] = useState([]);
    const [Excel, setExcel] = useState([]);
    const navigate = useNavigate()
    const token = getToken()

    const Input = styled('input')({
        display: 'none',
    });


    const handleOpen = (data) => {
        // if (data != '') {
        //   setData(data);
        // } else {
        //   setData('');
        // }
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    let array = []

    const resultArray = Excel.map(function (a) {

        return array.push({
            "name": a.name,
            "image": {
                "url": a.url,
                "public_id": a.public_id,
            },
        })
    },);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    useEffect(() => {
        getAllWallpaperHindi();
    });

    const getAllWallpaperHindi = async () => {
        if(!token){
          navigate('/')
        }
        setLoading(true);
        try {
            const res = await apiInstance.get('wallpapervs/get?type=Hindi');
            if (res.status === 200) {
                setData(res.data.data);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);


        }
    };
    let file
    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            file = files[0];

            const reader = new FileReader();

            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setExcel(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const blukReport = async () => {
        const reqObj = array
        setLoading(true)
        try {
            const response = await apiInstance.post('wallpapervs/create', reqObj);
            setLoading(false)
            getAllWallpaperHindi();
        } catch (e) {

        }
    }

    return (
        <>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppCommonSummary title="Wallpaper-Hindi" total={data ? data?.length : '0'} />
                    </Grid>
                </Grid>
                <Helmet>
                    <title> Wallpaper Hindi </title>
                </Helmet>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Wallpaper Hindi
                    </Typography>
                    <label htmlFor="contained-button-file" style={{ display: 'flex', alignItems: 'center' }} >
                        {/* <Input id="contained-button-file" multiple type="file" /> */}
                        <Button variant="contained" component="label" sx={{ borderRadius: '0px' }}>
                            Bluk upload
                            <input name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple type="file" />
                            <label className="custom-file-label" htmlFor="inputGroupFile" />
                        </Button>
                        <h4>{file?.name}</h4>
                        <Button variant='contained' onClick={blukReport} component="label" sx={{ borderRadius: '0px' }}>
                            Upload
                        </Button>
                    </label>
                    <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Add Wallpaper Hindi
                    </Button>
                </Stack>

                <Modal
                    open={openModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    disableBackdropClick
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose();
                        }
                    }}
                >
                    <WallpaperHindiForm handleClose={handleClose} getAllWallpaperHindi={getAllWallpaperHindi} />
                    {/* <AddTemplateForm onClick={handleClose} data={data ? data : ''} /> */}
                </Modal>
                {loading ? (
                    <CircularProgress />
                ) : data && data.length > 0 ? (
                    <ProductList data={data} getAllWallpaperHindi={getAllWallpaperHindi} type="wallpaperHindi" />
                ) : (
                    'No data found'
                )}
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
}
