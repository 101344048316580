import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  TextField,
  FormLabel,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Stack } from '@mui/system';
import { apiInstance } from '../httpClient/index';
import { useEffect } from 'react';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};
const EventForm = ({ handleClose, isEdit, singleData, showQuotesImage }) => {
  const [category, setCategory] = useState(singleData ? singleData?.industry?._id : '');
  const [author, setAuthor] = useState(singleData ? singleData?.city?._id : '');
  const [file, setFile] = useState(singleData ? singleData?.banner : '');
  const [uploadFile, setUploadFile] = useState('');
  const inputRef = React.useRef(null);
  const [authorData, setAuthorData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState('typing');
  const [name, setName] = useState(singleData ? singleData?.name : '');
  const [startDate, setStartDate] = useState(singleData ? singleData?.startDate : new Date());
  const [endDate, setEndDate] = useState(singleData ? singleData?.endDate : new Date());
  const [statusText, setStatusText] = useState(singleData ? singleData?.status : '');
  const [code, setCode] = useState(singleData ? singleData?.code : '');

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleAuthor = (e) => {
    setAuthor(e.target.value);
  };

  useEffect(() => {
    getAllCategory();
    getAllAuthor();
  }, []);

  const getAllCategory = async () => {
    try {
      const res = await apiInstance.get('/industry/get');
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (error) { }
  };

  const getAllAuthor = async () => {
    try {
      const result = await apiInstance.get('/city/get');
      if (result.status === 200) {
        setAuthorData(result.data.data);
      }
    } catch (error) { }
  };

  if (status === 'success') {
    return <h1>That's right!</h1>;
  }

  const fileUploder = async (formData) => {
    try {
      const res = await apiInstance.post(`/fileuploder/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data.data.url[0];
    } catch (error) {
      console.log(error);
    }
  }

  const AddQuotesImage = async () => {
    setLoader(true);
    setStatus('submitting');
    var date = new Date(startDate); // some mock date
    var startmilliseconds = date.getTime();

    var date = new Date(endDate); // some mock date
    var endmilliseconds = date.getTime();

    const formData = new FormData();
    formData.append("image", uploadFile);
    const res = await fileUploder(formData);

    

    const data = {
      "name": name,
      "startDate": parseInt(startmilliseconds),
      "endDate": parseInt(endmilliseconds),
      "banner": res ? res : singleData.banner,
      "city": author,
      "status": statusText,
      "code": parseInt(code)
    }
    console.log(data);


    if (isEdit) {
      try {
        const res = await apiInstance.put(`/event/update/${singleData._id}`, data);
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showQuotesImage();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    } else {
      try {
        const res = await apiInstance.post('/event/create', data);
        if (res.status === 200) {
          setLoader(false);
          setStatus('success');
          handleClose();
          showQuotesImage();
        }
      } catch (error) {
        setStatus('typing');
        setLoader(false);
      }
    }
  };
  const handleUpload = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  return (
    <>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={9}>
            <h3 style={{ margin: 0 }}>{isEdit ? 'Edit event' : 'Add event'}</h3>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ marginBottom: 2, marginTop: 3 }}>
          <Stack sx={{ marginTop: '10px' }}>
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <TextField
                  placeholder="Add here Name..."
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  type="text"
                  style={{ width: '100%', height: '100%' }}
                  id="outlined-multiline-flexible"
                  label="Name"
                  maxRows={4}
                />
              </Grid>
              <Grid container item xs={6} sx={{ marginTop: '3%', marginRight: '0%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={dayjs(startDate)}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid container item xs={6} sx={{ marginTop: '3%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={dayjs(endDate)}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </LocalizationProvider>
              </Grid>
              <FormLabel sx={{ marginTop: '3%' }}>File</FormLabel>
              <FormControl variant="outlined" fullWidth>
              
                  <>
                    <input
                      name="file"
                      hidden
                      type="file"
                      accept=".jpeg,.jpg,.mp4,.avi,.png,.webp"
                      multiple={false}
                      ref={inputRef}
                      onChange={handleUpload}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      Choose image
                    </Button>
                  </>

                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto',
                  }}
                >
                  {file ? (
                    <img src={file} alt="img" style={{ height: '100px', width: '100px' }} />
                  ) : singleData && singleData?.image ? (
                    <img src={singleData.banner} alt="img" style={{ height: '100px', width: '100px' }} />
                  ) : (
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#c5c5c5',
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <p>
                        Image <br /> Preview
                      </p>
                    </div>
                  )}
                </div>
              </FormControl>
            </Grid>
          </Stack>
          <Stack spacing={2} sx={{ marginTop: '3%' }}>
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Please select City..."
                  value={author}
                  label="City"
                  onChange={handleAuthor}
                >
                  {authorData &&
                    authorData.map((ele) => {
                      return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Stack>
          <Stack spacing={2} sx={{ marginTop: '3%' }}>
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Please Status..."
                  value={statusText}
                  label="City"
                  onChange={(e) => {
                    setStatusText(e.target.value);
                  }}
                >
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Planned"}>Planned</MenuItem>
                  <MenuItem value={"On-going"}>On-going</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  {/* {authorData &&
                    authorData.map((ele) => {
                      return <MenuItem value={ele._id}>{ele.name}</MenuItem>;
                    })} */}
                </Select>
              </FormControl>
            </Grid>
          </Stack>
          {/* <Grid container item xs={12}>
            <TextField
              placeholder="Add here Name..."
              onChange={(e) => {
                setStatusText(e.target.value);
              }}
              value={statusText}
              type="text"
              style={{ width: '100%', height: '100%' }}
              id="outlined-multiline-flexible"
              label="Status"
              maxRows={4}
            />
          </Grid> */}

          <Grid container item xs={12}>
            <TextField
              placeholder="Add Code..."
              onChange={(e) => {
                if(e.target.value.length==6){  
                  window.alert("Code shouldn't exceed 5 Digit") 
                } 
                setCode(e.target.value);
              }}
              value={code}
              type="number"
              style={{ width: '100%', height: '100%' }}
              id="outlined-multiline-flexible"
              label="Code"
            />
          </Grid>

        </Stack>
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: '3%', height: '100%' }}
          onClick={AddQuotesImage}
          disabled={status === 'submitting'}
        >
          {isEdit ? 'Update event' : 'Add event'}
          {loader && <CircularProgress color="inherit" size={15} style={{ marginLeft: '10px' }} />}
        </Button>
      </Box >
    </>
  );
};
export default EventForm;
