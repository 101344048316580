import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// components
import CategoryForm from '../components/CategoryForm';
import Iconify from '../components/iconify';
import USERLIST from '../_mock/user';
import { ProductList } from '../sections/@dashboard/products';
import { apiInstance } from '../httpClient';
import AppWidget from 'src/sections/@dashboard/app/AppCricleCategory';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from 'src/utils/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  allButton: {
    padding: '6px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
    cursor: 'pointer',
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const ButtonTypography = withStyles({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#424242',
    textAlign: 'center',
    borderRadius: '5px',
  },
})(Typography);

export default function CategoryPage() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [count, setCount] = useState({});
  const [isCardSelect, setIsCardSelect] = useState(false);
  const [isValueSelect, setIsValueSelect] = useState(0);
  const [getType, setGetType] = useState([]);
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate()
  const reqObj = state;


  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSelect = async (item) => {
    if (item.value === 'All Category') {
      setIsValueSelect(item.id);
      setIsCardSelect(false);
      getAllCategory();
      totalCount();
    } else {
      setIsValueSelect(item.id);
      setIsCardSelect(true);
      setLoading(true);
      try {
        const res = await apiInstance.get(`categoryvs/get?type=${item.value}`);

        if (res.status === 200) {
          setGetType(res.data.data);
          // setCount(res.data)
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleOpen = (data) => {
    // if (data != '') {
    //   setData(data);
    // } else {
    //   setData('');
    // }
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const Category = [
    {
      id: 1,
      name: `${'all category' + ' ' + ' | ' + `${data?.length}`}`,
      value: 'All Category',
    },
    {
      id: 2,
      name: `${'video' + ' ' + ' | ' + `${count?.Cvideo}`}`,
      value: 'Video',
    },
    {
      id: 3,
      name: `${'shayari-hindi' + ' ' + ' | ' + `${count?.CshayariHindin}`}`,
      value: 'Shayari-Hindi',
    },
    {
      id: 4,
      name: `${'shayari-english' + ' ' + ' | ' + `${count?.CshayariEnglish}`}`,
      value: 'Shayari-English',
    },
    {
      id: 5,
      name: `${'status-hindi' + ' ' + ' | ' + `${count?.CstatusHindi}`}`,
      value: 'Status-Hindi',
    },
    {
      id: 6,
      name: `${'status-image' + ' ' + ' | ' + `${count?.Cstatus}`}`,
      value: 'Status-Image',
    },
    {
      id: 7,
      name: `${'status-english' + ' ' + ' | ' + `${count?.CstatusEnglish}`}`,
      value: 'Status-English',
    },
    {
      id: 8,
      name: `${'wallpaper-image' + ' ' + ' | ' + `${count?.WallpaperImage}`}`,
      value: 'Wallpaper-Image',
    },
    {
      id: 9,
      name: `${'wallpaper-dp' + ' ' + ' | ' + `${count?.WallpaperDp}`}`,
      value: 'Wallpaper-Dp',
    },
    {
      id: 10,
      name: `${'wallpaper-hindi' + ' ' + ' | ' + `${count?.CwallpaperHindi}`}`,
      value: 'Wallpaper-Hindi',
    },
    {
      id: 11,
      name: `${'wallpaper-english' + ' ' + ' | ' + `${count?.CwallpaperEnglish}`}`,
      value: 'Wallpaper-English',
    },
    {
      id: 12,
      name: `${'jokes-hindi' + ' ' + ' | ' + `${count?.CjokeHindin}`}`,
      value: 'Jokes-Hindi',
    },
    {
      id: 13,
      name: `${'jokes-english' + ' ' + ' | ' + `${count?.CjokeEnglish}`}`,
      value: 'Jokes-English',
    },
    {
      id: 14,
      name: `${'stock-image' + ' ' + ' | ' + `${count?.StockImage}`}`,
      value: 'Stock-Image',
    },
    {
      id: 15,
      name: `${'stock-ringtone' + ' ' + ' | ' + `${count?.StockRingtone}`}`,
      value: 'Stock-Ringtone',
    },
  ];

  useEffect(() => {
    if (!isCardSelect) {
      getAllCategory();
      totalCount();
    } else {
    }
  }, [isCardSelect]);

  const token = getToken()
  console.log('token: ', token);

  const getAllCategory = async () => {
    if(!token){
      navigate('/')
    }
    setLoading(true);
    try {
      const res = await apiInstance.get('categoryvs/get');
      if (res.status === 200) {
        setData(res.data.data);
        setCount(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const totalCount = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get('videostatusvs/count');
      setCount(result?.data?.data);
    } catch (error) {}
  };

  return (
    <>
      <Container>
        <Helmet>
          <title> Category </title>
        </Helmet>
        <Grid container spacing={2}mb={1}>
          {Category.map((item) => {
            return (
              <Grid item xs={12} sm={2} md={2} style={{paddingTop:'0px'}}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isValueSelect === item.id ? 'white' : '#2065D1',
                    color: isValueSelect === item.id ? '#2065D1' : 'white',
                    // border: '1px solid black',
                  }}
                  fullWidth
                  className={classes.allButton}
                  onClick={() => handleSelect(item)}
                >
                  {item.name}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Category
          </Typography>
          <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Category
          </Button>
        </Stack>
        {/* <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Type"
            onChange={handleChange}
          >
            {Category.map((item) => {
              
              return (
                <>
                  <MenuItem value={item.id}>{item.value}</MenuItem>
                </>
              );
            })}
          </Select>
        </FormControl> */}
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <CategoryForm handleClose={handleClose} getAllCategory={getAllCategory} />
          {/* <AddTemplateForm onClick={handleClose} data={data ? data : ''} /> */}
        </Modal>
        {loading ? (
          <CircularProgress />
        ) : (
          <ProductList
            getType={getType}
            isCardSelect={isCardSelect}
            data={data}
            getAllCategory={getAllCategory}
            type="category"
          />
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
