import { getEmail, getToken } from "src/utils/common";

// ----------------------------------------------------------------------
const token = getToken();
if(token){
    var mail = getEmail().slice(1, - 1);
}
const account = {
  displayName: 'Admin',
  email: mail,
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
